import { Button, Modal, Select, Space, Switch, Tooltip, Typography, Upload } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { displayErrorNotification } from "../../../utils/messageServices/notificationService";
import _ from "lodash";
import { ReAuthenticationFormModal } from "../../security/ReAuthenticationForm.modal";
import { UploadCheckTableCSVModal } from "./UploadCheckCSVData.modal";
import { style } from "@mui/system";
import { v4_confirm_csv_upload, v4_uploadCSV } from "../../../routing/v4_Routes/csv.routes";
import { v4_getCsvSchemaData } from "../../../routing/v4_Routes/project.routes";
import { useSelector } from "react-redux";
import { displayErrorMessage } from "../../../utils/messageServices/messageService";
import { render } from "@testing-library/react";

const { Text } = Typography;

export function UploadCSVFileModal(props) {
    
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [fileList, setFileList] = React.useState([]);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const userId = useSelector((state) => state.userState.mongoUser?._id);
    const [replaceHeaders, setReplaceHeaders] = React.useState(false);
    const [changeEncoding, setChangeEncoding] = React.useState(false);
    const [encoding, setEncoding] = React.useState("utf-8");

    const handleUpload = async () => {
        setUploading(true);
        setLoading(true);

        if (fileList.length === 0) {
            setLoading(false);
            setUploading(false);
            return;
        };
        
        const response = await v4_uploadCSV(fileList[0], props._Id, replaceHeaders, changeEncoding, encoding)
 
        if (!_.isEmpty(response)) {
            setFileList([]);
            setData(response);
            setLoading(false);
            setUploading(false);
        } else {
            setLoading(false);
            setUploading(false);
            props.OnCancel();
        };


    };

    const confirm = async (data) => {
        setLoading(true);

        await v4_confirm_csv_upload(data, props._Id);
        setLoading(false);
        setFileList([]);
        setData([]);
        setColumns([]);
    };

    const getColumns = async () => {
        const response = await v4_getCsvSchemaData(props._Id);
        if (!_.isEmpty(response?.fields)) {
            setColumns(response.fields.map((field) => {
    
                    return {
                        title: field.displayName,
                        dataIndex: field.technicalName,
                        key: field.technicalName,
                        render: (text) => {
                            switch (field.type) {
                                case "Number":
                                    return <Text>{Number(text)}</Text>
                                case "String":
                                    return <Text>{text}</Text>
                                case "Date":
                                    return new Date(text).toLocaleDateString();
                                default:
                                    return <Text>{text}</Text>
                            };
                    }
                };
            }));
        };
    }

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        maxCount: 1,
        fileTypes: ["csv"],
    };

    useEffect(() => {
        getColumns();
    }, []);

    return (
        <>
            <Modal
                title="CSV Daten hochladen"
                open={props.Open}
                onCancel={props.OnCancel}
                footer={[
                    <Button key="back" onClick={props.OnCancel}>
                        Abbrechen
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
                        Hochladen
                    </Button>,
                ]}
                maskClosable={false}
            >
                <Text strong>Bitte laden Sie die CSV Datei hoch, um die Daten zu überprüfen. </Text>
                <br /><br />
                <Text>Die Daten werden nach dem Hochladen überprüft und können dann bestätigt werden. Achten Sie darauf in der CSV die richtigen Trennzeichen Semikolon ";" zu verwenden. Die Dateikodierung wird automatisch erkannt, kann aber manuell geändert werden.
                <br />
                <br />
                    <Space direction="vertical">
                        <div>
                            <Tooltip title="Unabhängig davon, ob die Spalten richtig angeordnet sind, werden die Spalten nach dem untenstehenden Schema verarbeitet. Achtung! Das kann zu einem Fehlerhaften Import führen!">
                                <Switch checked={replaceHeaders} onChange={() => setReplaceHeaders(!replaceHeaders)} />
                            </Tooltip> <Text>Ersetze die Kopfzeile durch die Schema-Kopfzeile</Text>
                        </div>
                        <div>
                            <Tooltip title="Dateikodierung manuell ändern, meist nicht notwendig. (Standard ist UFT-8 und ISO-8859-1 wird automatisch erkannt.)">
                                <Switch checked={changeEncoding} onChange={() => setChangeEncoding(!changeEncoding)} />
                            </Tooltip> <Text>Automatische Kodierungserkennung umgehen</Text>
                        </div>
                        {changeEncoding && <div>
                            <Tooltip title="Dateikodierung manuell ändern, meist nicht notwendig. (Standard ist UFT-8 und ISO-8859-1 wird automatisch erkannt.)">
                                <Select
                                style={{ width: 200 }}
                                    value={encoding}
                                    onChange={(value) => setEncoding(value)}
                                    disabled={!changeEncoding}
                                    children={encodingOptions.map((option) => {
                                        return <Select.Option value={option} key={option}>{option}</Select.Option>
                                    })}
                                    title="Dateikodierung"
                                    allowClear
                                />
                            </Tooltip>
                        </div>}
                    </Space>
                <br />
                <br />
                </Text>
                <Upload {...uploadProps}
                    
                >
                    <Button
                        type="primary"
                        loading={loading}
                        icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                    >CSV Tabelle hochladen</Button>
                </Upload>
            </Modal>

            <UploadCheckTableCSVModal Open={data?.length > 0} Data={data}
            Columns={columns}
            OnCancel={() => {
                setFileList([]);
                setData([]);
            }} OnConfirm={
                async () => {
                    await v4_confirm_csv_upload(data, props._Id, userId);
                    setData([]);
                    setFileList([]);
                    props.OnCancel();
                }
            } />

        </>
    )
};

const encodingOptions = [
    'windows-1250', 'windows-1251', 'windows-1252', 'windows-1253', 'windows-1254',
    'windows-1255', 'windows-1256', 'windows-1257', 'windows-1258', 'iso-8859-1',
    'iso-8859-2', 'iso-8859-3', 'iso-8859-4', 'iso-8859-5', 'iso-8859-6',
    'iso-8859-7', 'iso-8859-8', 'iso-8859-9', 'iso-8859-10', 'iso-8859-11',
    'iso-8859-13', 'iso-8859-14', 'iso-8859-15', 'iso-8859-16', 'x-mac-cyrillic',
    'x-mac-roman', 'x-mac-japanese', 'x-mac-chinesetrad', 'x-mac-chinesesimp', 'x-mac-korean',
    'x-mac-arabic', 'x-mac-hebrew', 'x-mac-greek', 'x-mac-thai', 'x-mac-turkish',
    'x-mac-ce', 'x-mac-icelandic', 'x-mac-romanian', 'x-mac-ukrainian', 'utf-8',
    'utf-16', 'utf-16le', 'utf-16be', 'utf-32', 'utf-32le', 'utf-32be',
    'shift_jis', 'euc-jp', 'iso-2022-jp', 'euc-kr', 'iso-2022-kr', 'gbk',
    'gb18030', 'big5', 'x-euc-tw', 'x-utf-16le', 'x-utf-16be', 'x-utf-32le',
    'x-utf-32be', 'x-iso-2022-jp', 'x-iso-2022-kr', 'x-windows-874', 'x-windows-1256'
]