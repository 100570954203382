import { Button, Drawer, Form, Space, Tag, Typography } from "antd";
import React, { useRef, useState } from "react";
import { TaskForm } from "../../forms/TaskForm";
import { route_createArticle } from "../../../routing/v3_Routes/v3_Routes";
import _ from "lodash";

const { Text, Paragraph } = Typography;


function TaskDialog(props) {
    //eslint-disable-next-line
    const action = props.Action;
    const formRef = useRef();
    //eslint-disable-next-line
    const [formData, setFormData] = useState({});
    //eslint-disable-next-line
    const [taskStatus, setTaskStatus] = useState({});
    const [multiple, setMultipleValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [splittable, setSplittable] = useState(false);

    const handleCancel = () => {
        props.setOpen(false);
        formRef.current.resetFields();
        setImage(null);
        setFormData({});
    };

    const onImageChange = (e) => {
        const fileList = e.target?.files
        if (_?.isEmpty(fileList) === false) {
            setImage(fileList[0]);
            console.debug("Image selected:", fileList[0]);
        } else {
            console.debug("No image selected");
        };
    };

    const handleFormChange = (e) => {
           setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.id]: e.target.value,
        }));
    };

    const calculateFormData = () => {
        const currentFormData = {...formRef.current.getFieldsValue()}
        console.debug("Fields Values:", currentFormData);

        for (const key in currentFormData) {
            if (currentFormData[key] === undefined) {
                delete currentFormData[key];
            }
        };

        const additionalData = { projectId: props.ProjectId,  project: props.ProjectId, newTask_multiple: multiple, splittable: splittable,  };
        const data = { ...currentFormData, ...additionalData };

        if (data.image) {
            delete data.image;
        };
        return data;
    };

    const handleTaskForm = (values) => {
        console.log("Task Form Values", values);
    };

    const customizeRequiredMark = (label, { required }) => (
        <>
            {required ? <Tag color="error">erforderlich</Tag> : <Tag color="warning">optional</Tag>}
            {label}
        </>
    );


    const submitFormData = async () => {
        const data = calculateFormData();
        const d = await route_createArticle(data);
        console.debug("Submitted Data:", data);
        console.debug("Created Article:", d);
        props.onCreated(d, image);
    };

    return (
            <Drawer
            destroyOnClose={true}
                title={`Neuen Artikel / Aufgabe erstellen`}
                centered
                open={props.open}
                onOk={() => { setIsLoading(true); submitFormData(); }}
                onCancel={handleCancel}
                width={"60%"}
                confirmLoading={isLoading}
                onClose={() => { props.setOpen(false) }}
                footer={
                    <div
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <Space>
                        <Button danger onClick={() => {setIsLoading(true); submitFormData();}} style={{ marginRight: 8 }}>
                            Speichern
                        </Button>
                        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                            Abbrechen
                        </Button>
                        </Space>
                    </div>
                }
            >
                <Paragraph type="h5">Projekt: {props.ProjectName}</Paragraph>
                <div>
                <Text type="primary">Bitte füllen Sie alle Felder aus, um eine neue Aufgabe / einen neuen Artikel zu erstellen. </Text>
                <Text type="primary">Es muss immer ein Titel für die Leistung eingeben werden. Eine interne HPM-Artikelnummer wird automatisch vergeben. Ein Titel kann neben einer Leistung wie z.B. "IBS bearbeiten" auch eine Artikelnummer 0287187-A sein. </Text>
                <br/><br/>
                <Text type="primary">Das erstellen von unterschiedlichen Artikel-Varianten wird noch nicht unterstützt. Das Feature ist bereits geplant.</Text>
                </div>
                <div style={{
                padding: "0% 5% 0% 0%",
                marginTop: "2%",
            }}>
                <Form
                    labelCol={{
                        span: 10,
                    }}
                    autoComplete="off"
                    layout="vertical"
                    onChange={handleFormChange}
                    name="newTask"
                    id="newTask"
                    onFinish={handleTaskForm}
                    ref={formRef}
                    requiredMark={customizeRequiredMark}
                    initialValues={{
                        title: "",
                        hasGuidlineDetails: false,
                        hasExtendedDetails: false,
                        multiple: false,
                        quanitity: 1,
                        status: "active",
                        currency: "EUR",
                        description: "N/A",
                        quantityIsVariable: false,
                        splittable: false
                    }}
                    noValidate={false}
                    validateTrigger="onChange"
                >
                    <TaskForm setStatus={(task) => { setTaskStatus(task) }} setMultiple={(multiple) => { setMultipleValue(multiple)}}
                    onImageChange={onImageChange}
                    splittable={(splittable) => { setSplittable(splittable) }}
                     />
                </Form>
                </div>
            </Drawer>
    )
};

export default TaskDialog;