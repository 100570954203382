import { Alert, Button, Checkbox, DatePicker, Form, InputNumber, Modal, Popconfirm, Space } from "antd";
import React, { useEffect } from "react";
import jsonLocale from "../../../locale/datePickerLocale.json";
import { border } from "@mui/system";
import { v4_price_change, v4_price_change_preview } from "../../../routing/v4_Routes/article.routes";
import dayjs from "dayjs";


export function PriceChangeModal(props) {

    const { task, open } = props;
    const formRef = React.useRef();
    const [reverse, setReverse] = React.useState(false); //Rückwirkend
    const [newPrice, setNewPrice] = React.useState(true);
    const [allPrices, setAllPrices] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        console.log(values);
        console.log(task);
        const result = await v4_price_change(task?.project, task?._id, values);
        console.log(result);
        await props.onUpdate(task?._id);
        setLoading(false);
        reset();
        props.onClose();
    };

    const onPreview = async (values) => {
        setLoading(true);
        console.log(values);
        console.log(task);
        await v4_price_change_preview(task?.project, task?._id, values);
        setLoading(false);
    };

    const reset = () => {
        formRef?.current?.resetFields();
        setReverse(false);
        setNewPrice(true);
        setAllPrices(false);
    };

    return (
        <Modal
            open={open}
            onCancel={() => {
                reset();
                props.onClose();
            }}
            title="Preisänderung"
            footer={null}
            destroyOnClose
            centered

        >
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={formRef}
                initialValues={{
                    newPriceDefault: true,
                    reverseChange: false,
                    allPrices: false
                }}
            >
                <Form.Item
                    label="Neuen Preis als Standard übernehmen?"
                    name="newPriceDefault"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn der Preis als neuer Standardpreis übernommen werden soll"
                    initialValue={newPrice}
                >
                    <Checkbox onChange={(e) => setNewPrice(e.target.checked)}>
                        Als Standard übernehmen
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    label="Neuer Preis"
                    name="newPrice"
                    rules={[
                        {
                            required: true,
                            message: "Bitte geben Sie den neuen Preis ein"
                        }
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        step={0.01}
                        precision={5}
                        decimalSeparator=","
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                    />
                </Form.Item>
                {!newPrice && !reverse && (<Form.Item>
                    <Alert
                message="Preisänderung nicht als Standard"
                description="So kann man keinen Preis ändern... Bitte wählen Sie die Option 'Neuen Preis als Standard übernehmen' aus, oder ändern Sie den Preis rückwirkend."
                type="error"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                {!newPrice && reverse && (<Form.Item>
                    <Alert
                message="Preisänderung nicht als Standard"
                description="Der Preis wird für den gewählten Zeitraum geändert. Der neue Preis wird aber nicht beibehalten. Dazu müssen Sie die Option 'Neuen Preis als Standard übernehmen' aktivieren."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                <Form.Item
                    label="Rückwirkende änderung?"
                    name="reverseChange"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn der Preis rückwirkend geändert werden soll"
                >
                    <Checkbox onChange={(e) => {
                        setReverse(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                newPriceDefault: true
                            });
                            setNewPrice(true);
                        } else {
                            setNewPrice(false);
                            formRef.current.setFieldsValue({
                                newPriceDefault: false
                            });
                        };
                    }}>Rückwirkend</Checkbox>
                </Form.Item>
                {reverse && (<Form.Item
                    label="Alle Preise ändern?"
                    name="allPrices"
                    valuePropName="checked"
                    tooltip="Wählen Sie diese Option, wenn der Preis rückwirkend geändert werden soll"
                >
                    <Checkbox onChange={(e) => {
                        setAllPrices(e.target.checked);
                        if (!e.target.checked) {
                            formRef.current.setFieldsValue({
                                reverse: null,
                                allPrices: false
                            });
                        } else {
                            formRef.current.setFieldsValue({
                                allPrices: true,
                                reverse: null,
                            });
                        };
                    }}>Alle Preise ändern</Checkbox>
                </Form.Item>)}
                {reverse && !allPrices && (<Form.Item
                    label="Zeitraum für Rückwirkende änderung"
                    name="reverse"
                    valuePropName="checked"
                >
                    <DatePicker.RangePicker
                        format={"DD.MM.YYYY"}
                        // ref={datePickerRef}
                        locale={jsonLocale}
                        maxDate={dayjs(new Date())}
                    // onChange={(value) => {
                    //     handleDateChange(value)
                    // }}
                    />
                </Form.Item>)}
                {reverse && !allPrices && (<Form.Item>
                    <Alert
                message="Achtung! - Rückwirkende änderung"
                description="Die rückwirkende änderung wird alle Preise für den angegebenen Zeitraum ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                {reverse && allPrices && (<Form.Item>
                    <Alert
                message="Achtung! - Alle Preise rückwirkend ändern"
                description="Die rückwirkende änderung wird alle Preise für alle Aufgaben ändern. Das betrifft auch bereits abgerechnete Aufgaben. Bitte prüfen Sie die Änderung sorgfältig."
                type="warning"
                showIcon
                style={{ marginTop: 20 }}
                />
                </Form.Item>)}
                <Form.Item>
                    <Space direction="horizontal">
                        <Button
                            style={{
                                backgroundColor: 'black',
                                color: 'white'
                            }}
                            onClick={() => { 
                                onPreview(formRef.current.getFieldsValue());
                            }}
                            loading={loading}
                        >
                            Vorschau berechnen und anzeigen
                        </Button>
                        <Popconfirm
                            title={reverse ? "Sind Sie sicher, dass Sie den Preis rückwirkend ändern möchten?" : "Sind Sie sicher, dass Sie den Preis ändern möchten?"}
                            onConfirm={() => { }}
                            okText="Ja"
                            okType="danger"
                            cancelText="Nein"
                            okButtonProps={{ type: "primary", htmlType: "submit", onClick: () => formRef.current.submit(), loading: loading }}
                        >
                            <Button
                                type="primary"
                                danger
                                disabled={!newPrice && !reverse}
                                loading={loading}
                            >
                                Preis ändern
                            </Button>
                        </Popconfirm>
                    </Space>
                </Form.Item>
            </Form>
            
        </Modal>
    );
};